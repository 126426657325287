<template>
  <b-card>
    <div v-if="isLoading">
      <aom-skeleton-loader />
    </div>
    <div v-else>
      <div
        v-for="(resources, group) in groupedResources"
        :key="group"
        class="mb-3"
      >
        <b-row>
          <b-col sm="12">
            <h4>{{ group }}</h4>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            v-for="(resource, index) in resources"
            :key="index"
            sm="6"
            lg="4"
            cols="12"
            class="mb-2"
          >
            <b-link
              :href="getResourceLink(resource)"
              :target="getResourceLinkTarget(resource)"
            >
              <b-card
                no-body
                border-variant="primary"
                tag="article"
                class="overflow-hidden"
              >
                <b-row no-gutters>
                  <b-col :sm="resource.type_id === resourceTypes.FILE ? 8 : 12">
                    <b-card-body>
                      <b-card-text>
                        <b-card-title
                          v-b-tooltip.hover="`${resource.translations[0].title}`"
                          class="mb-1"
                        >
                          <feather-icon
                            v-if="resource.type_id === resourceTypes.FILE"
                            icon="DownloadIcon"
                            size="16"
                          />
                          <feather-icon
                            v-else
                            icon="LinkIcon"
                            size="16"
                          />
                          {{ resource.translations[0].title }}
                        </b-card-title>
                        <p class="mb-0">
                          <b-badge
                            v-for="role in getRoleBadges(resource.roles)"
                            :key="role"
                            variant="primary"
                            class="font-weight-normal"
                            style="margin-right: 2px;"
                          >
                            {{ role }}
                          </b-badge>
                        </p>
                      </b-card-text>
                    </b-card-body>
                  </b-col>

                  <b-col
                    v-if="resource.type_id === resourceTypes.FILE"
                    sm="4"
                  >
                    <b-card-body>
                      <b-card-img
                        :src="getResourceThumbnail(resource)"
                        :alt="resource.translations[0].title"
                        class="rounded-0"
                      />
                    </b-card-body>
                  </b-col>
                </b-row>
              </b-card>
            </b-link>
          </b-col>
        </b-row>
      </div>

      <b-row>
        <b-col>
          <p>You may download resources one at a time, or download all resources in a compressed (zip) file.</p>
          <b-button
            variant="primary"
            @click="onDownloadProgramResourcesZip"
          >
            Download all resources
          </b-button>
        </b-col>
      </b-row>
    </div>
    <section v-if="groupedResources.length === 0">
      <div class="text-center m-4">
        <b-avatar
          size="72"
          variant="primary"
          class="mr-2"
        >
          <feather-icon
            icon="SearchIcon"
            size="42"
          />
        </b-avatar>
        <h3 class="mt-1">
          No Resources found
        </h3>
        <p class="text-muted">
          There weren't any Resources found or available for you to view.
        </p>
      </div>
    </section>
  </b-card>
</template>

<script>
import { mapGetters } from "vuex";
import {
  BRow,
  BCol,
  BAvatar,
  BCard,
  BCardBody,
  BCardImg,
  BCardTitle,
  BLink,
  BCardText,
  BBadge,
  VBTooltip,
  BButton
} from "bootstrap-vue";
import resourcesService from "@/services/resourcesService";
import { makeErrorToast } from "@/libs/utils";
import { groupBy } from "lodash";
import { resourceTypes, userRoles } from "@/models";
import AomSkeletonLoader from "@aom-core/AomSkeletonLoader.vue";
import { BE_API_URL } from "@/constants/app";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BCardImg,
    BCardTitle,
    BLink,
    BCardText,
    BBadge,
    AomSkeletonLoader,
    BAvatar,
    BButton
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      isLoading: false,
      groupedResources: [],
      defaultThumbnail: require("@/assets/images/elements/default-resource-thumbnail.jpg"),
      resourceTypes: resourceTypes,
    };
  },
  computed: {
    ...mapGetters("participants", [
      "program",
      "mentorDisplay",
      "menteeDisplay",
      "traineeDisplay",
    ]),
    profile() {
      return this.$store.getters["profile/profile"];
    },
  },
  created() {
    this.loadItems();
  },

  methods: {
    async loadItems() {
      try {
        this.isLoading = true;
        const response = await resourcesService.getProgramResources(
          this.program.id
        );
        const resources = response.data.items.map(item => ({
          ...item,
          roles: item.roles
            .map(r => {
              if (r.id === userRoles.MENTOR) {
                return this.mentorDisplay;
              }
              if (r.id === userRoles.MENTEE) {
                return this.menteeDisplay;
              }
              if (r.id === userRoles.TRAINEE) {
                return this.traineeDisplay;
              }
              return "";
            })
        }));
        this.groupedResources = groupBy(resources, item => item.category.name);
      } catch (e) {
        this.$toast(makeErrorToast("Resources list not loaded."));
        this.$log.error(e);
      } finally {
        this.isLoading = false;
      }
    },

    getResourceThumbnail(item) {
      if (item.translations && item.translations[0].thumbnail_url) {
        return item.translations[0].thumbnail_url;
      }
      return this.defaultThumbnail;
    },

    getResourceLink(item) {
      if (item.type_id === resourceTypes.FILE || item.type_id === resourceTypes.WEB_PAGE) {  
        // Go to detail page
        return this.$router.resolve({ name: 'participant-resource', params: { resource: item.id } }).href;
      } else if (item.translations && item.translations[0].link) {
        // Go to link
        return item.translations[0].link;
      }
      return "#";
    },

    getResourceLinkTarget(item) {
      if (item.type_id === resourceTypes.URL) {
        return '_blank';
      }
      return '_self';
    },

    getRoleBadges(resourceRoles) {
      const profileRoles = this.profile.roles.filter(r => r === userRoles.MENTOR || r === userRoles.MENTEE || r === userRoles.TRAINEE);
      if (profileRoles && profileRoles.length >= 2) {
        return resourceRoles;
      } else if (profileRoles[0] === userRoles.MENTOR) {
        return resourceRoles.filter(r => r === this.mentorDisplay);
      } else if (profileRoles[0] === userRoles.MENTEE) {
        return resourceRoles.filter(r => r === this.menteeDisplay);
      } else if (profileRoles[0] === userRoles.TRAINEE) {
        return resourceRoles.filter(r => r === this.traineeDisplay);
      }
      
      return resourceRoles;
    },

    onDownloadProgramResourcesZip() {
      window.document.location = `${BE_API_URL}/programs/${this.program.id}/resources/download`;
    }
  },
};
</script>
